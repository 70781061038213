section.testimonials-section {

    .panel-image {
        @media screen and (max-width: 576px) {
            & {
                margin-left: 4vw;
            }
        }
    }

&.testimonials {
    blockquote {
        p {
            font-family: 'ivypresto-text', serif !important;
            @include media-breakpoint-up(xxl) {
                & {
                    font-size: 20px;
                    line-height: 34px;
                }
            }
            @include media-breakpoint-down(xxl) {
                font-size: 17px;
                line-height: 30px;
            }
        }
        

        footer {
            cite {
                span {
                    letter-spacing: 0.2em !important;
                }
            }
        }
    }
    .testimonial-wrapper {
        background: linear-gradient(#f3f0eb 0%, #efe9e2 100%);
        border: 15px solid #fff;
        border-right: none !important;
        @media screen and (max-width: 991px) {
            & {
                margin-bottom: 0px !important;
                margin-top: -85px !important;
            }
        }
        @include media-breakpoint-up(xxl) {
            & {
                margin-bottom: -100px;
                padding: 50px 140px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                margin-bottom: -120px;
                padding: 50px 110px;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                margin-bottom: -200px;
                padding: 40px 55px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 40px 25px;
            }
        }

    }
}
    
}